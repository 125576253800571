import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonSelect, MenuController, ModalController, NavController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ChangeSchoolComponent } from 'src/app/components/change-school/change-school.component';
import { StorageKeys } from 'src/app/models/storage.enum';
import { AuthService } from 'src/app/services/authservice.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { StorageService } from 'src/app/services/storage.service';
import { UiService } from 'src/app/services/ui-service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
})
export class MenuPage implements OnInit, OnDestroy {
  @ViewChild('mySelect') selectRef: IonSelect|any;

  public fullName:any;
  public isDesktop = false;
  public appPages:any = [];
  public selectedIndex = null;
  public extraIndex = null;
  public extraPages = [];
  display_mode:any;
  userDetail: any;
  subscription: Subscription|any;
  isThemeclicked: boolean = false;
  public themeOptions = [
    { type: 'radio', label: 'System Theme', value: 'default' },
    { type: 'radio', label: 'Dark', value: 'dark' },
    { type: 'radio', label: 'Light', value: 'light' },
  ]
  public selectedCompany:any;


  constructor(
    private platform: Platform,
    private authService: AuthService,
    private dashboardService: DashboardService,
    private navController: NavController,
    public storage: StorageService,
    public uiService:UiService,
    public menuCtrl: MenuController,
    public modalCtrl:ModalController,
    public storageService:StorageService
    ) {  }

  toggleDarkTheme(shouldAdd:any) {
    this.uiService.darkMode = shouldAdd;
    this.storage.set('darkmode', shouldAdd)
    if(shouldAdd){
      document.body.classList.remove('light');
      document.body.classList.toggle('dark', shouldAdd);
    }else{
      document.body.classList.remove('dark');
      document.body.classList.toggle('light',true);
    }
  }

  async ngOnInit() {
    this.isDesktop = this.platform.is('desktop');
    this.dashboardService.getMenu().then(()=>{
      this.dashboardService.menuList$.subscribe((menuList) => {
        console.log(this.appPages)
        this.appPages = this.processOutputData1(menuList);
        //Remove USER & COMPANYS, MISC, SETTINGS, USERPROFILE
        let item:any=this.appPages.findIndex((item:any)=>item.title==="USER & COMPANIES");
        this.appPages[item].isVisible=false;
        item=this.appPages.findIndex((item:any)=>item.title==="MISC");
        if(item!=-1){
          this.appPages[item].isVisible=false;
        }
        item=this.appPages.findIndex((item:any)=>item.title==="SETTINGS");
        this.appPages[item].isVisible=false;
        item=this.appPages.findIndex((item:any)=>item.title==="USER PROFILE");
        this.appPages[item].isVisible=false;
      });
    })
    this.userDetail = await this.authService.getUserDetail();
    this.selectedCompany=await this.storageService.get(StorageKeys.selectedCompanyName);
    this.fullName=this.userDetail.First_Name + " " + this.userDetail.Last_Name;
  }

  processOutputData1(data:any){
    let dataTransform=data;
    for(let item of dataTransform)
    {
     item.isVisible=true;
    }
    return dataTransform;
  }

  async changeSchool(){
    const modal = await this.modalCtrl.create({
      component: ChangeSchoolComponent,
      cssClass: 'school-modal'
    })
    await modal.present();
  }

  async menuOpened() {
    this.selectedCompany=await this.storageService.get(StorageKeys.selectedCompanyName);
   /*  this.storage.get('themeName').then((data)=>{
      this.selectRef.value=data;
    }) */
  }
  ngOnDestroy() {
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }
  async logout() {
    this.dashboardService.menuTitle = "";
    await this.authService.logout();
    await this.storageService.remove(StorageKeys.userDetail);
    await this.storageService.remove('access-token');
    this.menuCtrl.close();
    this.navController.navigateRoot("/login");

  }

 async navigateTo(title:any) {
    this.dashboardService.pageClick.next(title);
    switch (title.toLowerCase()) {
      case "lead dashboard" :
        this.navController.navigateRoot('/main/dashboard')
        break;
      case "service dashboard" :
        this.navController.navigateRoot('/main/home')
        break;
      case "tour dashboard" :
        this.navController.navigateRoot('/main/tourDashboard')
        break;
      case "leads list":
        this.navController.navigateRoot('/main/leadlist')
        break;
      case "leads analysis":
        this.navController.navigateRoot('/main/lead-analysis')
        break;
      case "follow up analysis":
        this.navController.navigateRoot('/main/followupanalysis')
        break;
      case "tours analysis":
        this.navController.navigateRoot('/main/touranalysis')
        break;
      case "campaign analysis":
        this.navController.navigateRoot('/main/campaignanalysis')
        break;
      case "manage events":
        this.navController.navigateRoot('/main/manageevents')
        break;
      case "campaigns":
        this.navController.navigateRoot('/main/campaigns')
        break;
      case "templates":
        this.navController.navigateRoot('/main/emailtemplates')
        break;
      case "process rules":
        this.navController.navigateRoot('/main/processrules')
        break;
      case "scheduled processes":
        this.navController.navigateRoot('/main/schedule')
        break;
      case "companies":
        this.navController.navigateRoot('/main/companies')
        break;
      case "user companies":
        this.navController.navigateRoot('/main/usercompanies')
        break;
      case "user settings":
        this.navController.navigateRoot('/main/usersettings')
        break;
      case "list of values settings":
        this.navController.navigateRoot('/main/lovsettings')
        break;
      case "cross ref. settings":
        this.navController.navigateRoot('/main/xrefsettings')
        break;
      case "settings":
        this.navController.navigateRoot('/main/settings')
        break;
      case "recyclebin":
        this.navController.navigateRoot('/main/recyclebin')
        break;
      case "custom reports":
        this.navController.navigateRoot('/main/customanalysis')
        break;
      case "new web leads":
        this.navController.navigateRoot('/main/weblead')
        break;
      case "image files":
        this.navController.navigateRoot('/main/imagefilelist')
        break;
      case "terms and conditions":
        this.navController.navigateRoot('/tnc')
        break;
      case "log out":
        this.logout();
        break;
      case "sms":
        this.navController.navigateRoot('/main/sms');
        break;
     default:
        break;
    }

    this.menuCtrl.close();
  }

  getIconImage(filename:any,isSubMenu=false){
    var filePath;
    if(filename.toLowerCase().trim() == "settings and  privacy"){
      filePath="/assets/images/menu/settingsPrivacy.png";
    }
    else{
      if(filename=='SMS' && isSubMenu){
        filePath="/assets/images/menu/" + filename + "1.png";
      }
      else{
        filePath="/assets/images/menu/" + filename + ".png";
      }
    }
    return filePath;
  }

  selectTheme(event:any) {

   this.storage.set('themeName',event.target.value);
    switch (event.target.value) {
      case 'default':
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
        this.toggleDarkTheme(prefersDark.matches);
        prefersDark.addListener((mediaQuery) => this.toggleDarkTheme(mediaQuery.matches));
        break;
      case 'dark':
        this.display_mode = true;
        this.toggleDarkTheme(this.display_mode);
        break;
      case 'light':
        this.display_mode = false;
        this.toggleDarkTheme(this.display_mode);
        // this.toggleDarkTheme(this.display_mode);
        break;

      default:
        break;
    }
  }

  modeChange() {
    this.isThemeclicked = true;
    setTimeout(() => {
      this.selectRef.open();
    }, 20);
    // this.display_mode = !this.display_mode;
    // this.toggleDarkTheme(this.display_mode);
  }

  profilePage() {
    this.navController.navigateForward('/main/profile');
    this.menuCtrl.close();
  }
}
