import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private storage: Storage) {}

  public get(key: string): Promise<any> {
    return this.storage.get(key);
  }

  public set(key: string, value: any): Promise<any> {
    return this.storage.set(key, value);
  }

  public remove(key: string): Promise<any> {
    return this.storage.remove(key);
  }

  public clear(): Promise<any> {
    return this.storage.clear();
  }
}
