import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './authservice.service';
import { StorageService } from './storage.service';
import { StorageKeys } from '../models/storage.enum';

@Injectable({
    providedIn: 'root',
})
export class ListofValuesService {
    public lovListrel$ = new BehaviorSubject([]);
    public lovListSource$ = new BehaviorSubject([]);
    public lovListReferralType$ = new BehaviorSubject([]);
    public lovListState$ = new BehaviorSubject([]);
    public lovListinqType$ = new BehaviorSubject([]);
    public lovListpc$ = new BehaviorSubject([]);
    public lovListexp$ = new BehaviorSubject([]);
    public lovListtour$ = new BehaviorSubject([]);
    public lovListres$ = new BehaviorSubject([]);
    public lovListfollowup$ = new BehaviorSubject([]);
    public lovListfollowupStatus$ = new BehaviorSubject([]);
    public lovListpt$ = new BehaviorSubject([]);
    public lovListLeadStatus$ = new BehaviorSubject([]);
    public lovListcampType$ = new BehaviorSubject([]);
    public lovListcampStatus$ = new BehaviorSubject([]);
    public lovListPersonalize$ = new BehaviorSubject([]);
    public lovListenrollStatus$=new BehaviorSubject([]);
    public lovListresRole$=new BehaviorSubject([]);
    public selectedCompany:any;
    public listofValues$=new BehaviorSubject([]);
    public userInfo:any;

    accessToken:string | null | undefined;
    pageClick = new BehaviorSubject("");

    constructor(private http: HttpClient, private authService: AuthService, public storage: StorageService) { }

    async init() {
      this.userInfo=await this.authService.getUserDetail();
      let retVal=localStorage.getItem(StorageKeys.userDetail)
      this.userInfo=retVal!=null?JSON.parse(retVal):'';
      this.selectedCompany=this.userInfo.DefaultCompany_ID;
     }

    async ionViewWillEnter(){
      this.userInfo=await this.authService.getUserDetail();
      let retVal=localStorage.getItem(StorageKeys.userDetail)
      this.userInfo=retVal!=null?JSON.parse(retVal):'';
      let selectCompany = await this.storage.get(StorageKeys.selectedCompany);
        if(selectCompany!= "" && selectCompany != null){
          this.selectedCompany=parseInt(selectCompany);
        }
    }

     FetchResults(input:any){
      // return new Promise(async (resolve) => {
        this.getAccessToken();
        let retVal=localStorage.getItem(StorageKeys.userDetail)
        this.userInfo=retVal!=null?JSON.parse(retVal):'';
        input.User_ID=this.userInfo.User_ID;
        input.AccessToken=this.accessToken;
        let data=JSON.stringify(input,null,2);
        console.log(data);
        return this.http.post<any>(environment.apiUrl + 'ListOfValues', input)
        /* .subscribe((resp) => {
          this.listofValues$.next((resp && resp.length > 0) ? resp : []);
          resolve(resp);
        }, (err) => {
          console.log(err);
        })
      }) */
    }

    async getAccessToken(){
      this.accessToken= localStorage.getItem(StorageKeys.AccessToken);
      //this.userInfo=await this.authService.getUserDetail();
    }

    saveLOV(formData:any)
    {
      let retVal=localStorage.getItem(StorageKeys.userDetail)
      let userDetail=retVal!=null?JSON.parse(retVal):'';
        this.selectedCompany=localStorage.getItem(StorageKeys.selectedCompany);
        this.accessToken = localStorage.getItem(StorageKeys.AccessToken);
        let parseData = {
          Company_ID:this.selectedCompany,
          USER_ID: userDetail.User_ID,
          AccessToken:this.accessToken
        };
        let param = { ...parseData, ...formData };
        //if (userDetail)
        //{
            return this.http.post<any>(environment.apiUrl + 'ListOfValues', param)
        //}
    }

      async getListOfValues(listType:any) {
        return new Promise(async (resolve) => {
          let userDetail = await this.authService.getUserDetail();
          this.selectedCompany=userDetail.DefaultCompany_ID;
          this.accessToken = await this.storage.get(StorageKeys.AccessToken);
          this.selectedCompany= await this.storage.get(StorageKeys.selectedCompany)
          if(this.selectedCompany==null){
            await this.storage.get(StorageKeys.userDetail).then(res=>{
              this.selectedCompany=res.DefaultCompany_ID;
            });
          }
          let param = {
            Company_ID:this.selectedCompany,
            USER_ID: userDetail.User_ID,
            AccessToken:this.accessToken,
            ListType:listType
          };
          if (userDetail)
          {
              /* param['ActionType'] = actionType;
              param['AccessToken'] = this.accessToken; */
              this.http.post<any>(environment.apiUrl + 'ListOfValues', param).subscribe((resp) => {
                if(listType=="Relationship"){
                  this.lovListrel$.next((resp && resp.length > 0) ? resp : []);
                }
                else if(listType=="Source"){
                  this.lovListSource$.next((resp && resp.length > 0) ? resp : []);
                }
                else if(listType=="ReferralType"){
                  this.lovListReferralType$.next((resp && resp.length > 0) ? resp : []);
                }
                else if(listType=="State"){
                  this.lovListState$.next((resp && resp.length > 0) ? resp : []);
                }
                else if(listType=="InquiryType"){
                  this.lovListinqType$.next((resp && resp.length > 0) ? resp : []);
                }
                else if(listType=="ProgramCode"){
                  this.lovListpc$.next((resp && resp.length > 0) ? resp : []);
                }
                else if(listType=="ExplorationStatus"){
                  this.lovListexp$.next((resp && resp.length > 0) ? resp : []);
                }
                else if(listType=="EnrollmentStatus"){
                  this.lovListenrollStatus$.next((resp && resp.length > 0) ? resp : []);
                }
                else if(listType=="ProgramTime"){
                  this.lovListpt$.next((resp && resp.length > 0) ? resp : []);
                }
                else if(listType=="TourStatus"){
                  this.lovListtour$.next((resp && resp.length > 0) ? resp : []);
                }
                else if(listType=="ResponsibleStaff"){
                  this.lovListres$.next((resp && resp.length > 0) ? resp : []);
                }
                else if(listType=="FollowupType"){
                  this.lovListfollowup$.next((resp && resp.length > 0) ? resp : []);
                }
                else if(listType=="FollowupStatus"){
                  this.lovListfollowupStatus$.next((resp && resp.length > 0) ? resp : []);
                }
                else if(listType=="LeadStatus"){
                  this.lovListLeadStatus$.next((resp && resp.length > 0) ? resp : []);
                }
                else if(listType=="CampaignType"){
                  this.lovListcampType$.next((resp && resp.length > 0) ? resp : []);
                }
                else if(listType=="CampaignStatus"){
                  this.lovListcampStatus$.next((resp && resp.length > 0) ? resp : []);
                }
                else if(listType=="Personalization"){
                  this.lovListPersonalize$.next((resp && resp.length > 0) ? resp : []);
                }
                else if(listType=="ResponsibleRole"){
                  this.lovListresRole$.next((resp && resp.length > 0) ? resp : []);
                }
                resolve(resp);
            }, (err) => {
                console.log(err);
            })
          }
          else {
            resolve(null);
          }
        })
      }
}
