import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { StorageKeys } from '../models/storage.enum';
import { NavController } from '@ionic/angular';
import { UiService } from './ui-service';


@Injectable({
  providedIn: 'root',
})
export class AuthService {

  public TermsAccepted$  = new BehaviorSubject([]);

  constructor(private http:HttpClient,
    private storageService: StorageService,
    private navController: NavController,
    private uiService: UiService) { }

  login(data:any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + 'user', data)
  }

  async checkifTermsAccepted(){
    let userDetail = await this.getUserDetail();
    let accessToken=localStorage.getItem(StorageKeys.AccessToken)
    if (userDetail!=null) {
      let data={
        SchoolID:userDetail.DefaultCompany_ID,
        UserID:userDetail.User_ID,
        AccessToken:accessToken,
        ActionType: "TermsAccepted"
      }
      this.http.post<any>(environment.apiUrl + 'Portal', data).subscribe(
        (resp) => {
          this.TermsAccepted$.next(resp && resp.length > 0 ? resp : []);
        },
        (err) => {
          console.log(err);
        }
      );
    }

  }

  async FetchToken() {
    let token = await this.storageService.get(StorageKeys.AccessToken);
    return token;
  }

  async logout() {
    let userDetail = await this.getUserDetail();
    let param = {
      "ActionType": "LogOut",
      "UserID": userDetail.UserID
    }
    await this.storageService.remove(StorageKeys.userDetail);
    await this.storageService.remove('access-token');
    localStorage.removeItem(StorageKeys.userDetail);
    localStorage.removeItem(StorageKeys.AccessToken);
    /* this.http.post(environment.apiUrl + 'Portal', param).subscribe(async (data) => {
      try {
        await this.storageService.remove(StorageKeys.userDetail);
        await this.storageService.remove('access-token');
        this.navController.navigateRoot("/login");
      } catch (error) {
        let message = (error && error.message) ? error.message : "Something went wrong with logout please try again";
        this.uiService.showError(message);
      }
    }) */
  }

  async saveUserToStorage(data:any): Promise<any> {
    await this.storageService.set(StorageKeys.userDetail, data);
    await this.storageService.set(StorageKeys.AccessToken,data.AccessToken);
    await this.storageService.set(StorageKeys.selectedCompany,data.DefaultCompany_ID);
    await this.storageService.set(StorageKeys.selectedCompanyName,data.DefaultCompany_Name);

    localStorage.setItem(StorageKeys.selectedCompany,data.DefaultCompany_ID);
    localStorage.setItem(StorageKeys.userID,data.User_ID);
    localStorage.setItem(StorageKeys.AccessToken,data.AccessToken);
  }

  async getUserDetail() {
    let userInfo = await this.storageService.get(StorageKeys.userDetail);
    return userInfo;
  }

  sendVerification(data:any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + 'Portal', data)
  }

}
