import { Component, HostListener } from '@angular/core';
import { AuthService } from './services/authservice.service';
import { DashboardService } from './services/dashboard.service';
import { Storage } from '@ionic/storage-angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public spinMsg = "Loading..."
  constructor(private dashboardService: DashboardService,private authService:AuthService,private storage: Storage) {}

  ngOnInit() {
    // this.isDesktop = this.platform.is('desktop');
    this.createdb();
    this.dashboardService.init();
  }

  async createdb(){
    await this.storage.create();
  }

  /* @HostListener('window:beforeunload')
  async ngOnDestroy(){
    this.authService.logout();
  } */
}
