import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MenuPage } from './pages/menu/menu.page';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'main',
    component: MenuPage,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule) },
      {
        path: 'leads',
        loadChildren: () => import('./pages/leads/leads.module').then( m => m.LeadsPageModule)
      },
      {
        path: 'weblead',
        loadChildren: () => import('./pages/leads/weblead/weblead.module').then( m => m.WebleadPageModule)
      },
      {
        path: 'lead-calendar',
        loadChildren: () => import('./pages/leads/lead-calendar/lead-calendar.module').then( m => m.LeadCalendarPageModule)
      },
      {
        path: 'leadlist',
        loadChildren: () => import('./pages/leads/leadlist/leadlist.module').then( m => m.LeadlistPageModule)
      },
      {
        path: 'leadlist/:id',
        loadChildren: () => import('./pages/leads/leadlist/leadlist.module').then( m => m.LeadlistPageModule)
      },
      {
        path: 'newInquiry',
        loadChildren: () => import('./components/inquiry-details/newinquiry/newinquiry.component').then( m => m.NewinquiryComponent)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
      },
      {
        path: 'lead-analysis',
        loadChildren: () => import('./pages/bireports/lead-analysis/lead-analysis.module').then( m => m.LeadAnalysisPageModule)
      },
      {
        path: 'followupanalysis',
        loadChildren: () => import('./pages/bireports/followup-analysis/followupanalysis.module').then( m => m.FollowupanalysisPageModule)
      },
      {
        path: 'touranalysis',
        loadChildren: () => import('./pages/bireports/tour-analysis/tour-analysis.module').then( m => m.TourAnalysisPageModule)
      },
      {
        path: 'campaignanalysis',
        loadChildren: () => import('./pages/marketing/campaign-analysis/campaign-analysis.module').then( m => m.CampaignAnalysisPageModule)
      },
      {
        path: 'campaigns',
        loadChildren: () => import('./pages/marketing/campaigns/campaigns.module').then( m => m.CampaignsPageModule)
      },
      {
        path: 'manageevents',
        loadChildren: () => import('./pages/marketing/manage-events/manage-events.module').then( m => m.ManageEventsPageModule)
      },
      {
        path: 'emailtemplates',
        loadChildren: () => import('./pages/Emails/templates/templates.module').then( m => m.TemplatesPageModule)
      },
      {
        path: 'processrules',
        loadChildren: () => import('./pages/Automation/process-rules/process-rules.module').then( m => m.ProcessSchedulePageModule)
      },
      {
        path: 'schedule',
        loadChildren: () => import('./pages/Automation/scheduled-processes/scheduled-processes.module').then( m => m.ScheduledProcessesPageModule)
      },
      {
        path: 'companies',
        loadChildren: () => import('./pages/Admin/companies/companies.module').then( m => m.CompaniesPageModule)
      },
      {
        path: 'usercompanies',
        loadChildren: () => import('./pages/Admin/user-companies/user-companies.module').then( m => m.UserCompaniesPageModule)
      },
      {
        path: 'usersettings',
        loadChildren: () => import('./pages/Admin/user-settings/user-settings.module').then( m => m.UserSettingsPageModule)
      },
      {
        path: 'lovsettings',
        loadChildren: () => import('./pages/Admin/lovsettings/lovsettings.module').then( m => m.LOVSettingsPageModule)
      },
      {
        path: 'xrefsettings',
        loadChildren: () => import('./pages/Admin/cross-ref-settings/cross-ref-settings.module').then( m => m.CrossRefSettingsPageModule)
      },
      {
        path: 'recyclebin',
        loadChildren: () => import('./pages/Admin/recycle-bin/recycle-bin.module').then( m => m.RecycleBinPageModule)
      },
      {
        path: 'toursettings',
        loadChildren: () => import('./pages/Admin/tour-settings/tour-settings.module').then( m => m.TourSettingsPageModule)
      },
      {
        path: 'customanalysis',
        loadChildren: () => import('./pages/bireports/custom-analysis/custom-analysis.module').then( m => m.CustomAnalysisPageModule)
      },
      {
        path: 'imagefilelist',
        loadChildren: () => import('./pages/Emails/image-files/image-files.module').then( m => m.ImageFilesPageModule)
      },
      {
        path: 'edit-profile',
        loadChildren: () => import('./pages/edit-profiles/edit-profiles.module').then( m => m.EditProfilesPageModule)
      },
      {
        path: 'tourDashboard',
        loadChildren: () => import('./pages/tour-dashboard/tour-dashboard.module').then( m => m.TourDashboardPageModule)
      },
      {
        path: 'sms',
        loadChildren: () => import('./pages/sms/sms.module').then( m => m.SmsPageModule)
      },
      {
        path: 'add-edit-template',
        loadChildren: () => import('./pages/add-edit-template/add-edit-template.module').then( m => m.AddEditTemplatePageModule)
      },
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'events/:id',
    loadChildren: () => import('./pages/dynamic-page/dynamic-page.module').then( m => m.DynamicPagePageModule)
  },
  {
    path: 'tnc',
    loadChildren: () => import('./pages/tnc/tnc.module').then(m => m.TncPageModule)
  },
  {
    path: 'reg-page',
    loadChildren: () => import('./pages/reg-page/reg-page.module').then( m => m.RegPagePageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules,onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
