import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { StorageKeys } from 'src/app/models/storage.enum';
import { DashboardService } from 'src/app/services/dashboard.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-change-school',
  templateUrl: './change-school.component.html',
  styleUrls: ['./change-school.component.scss'],
})
export class ChangeSchoolComponent implements OnInit {

  Companys:any;
  schoolNames:any;
  isLoaded:boolean =false;

  constructor(public storage: StorageService,public modalCtrl: ModalController,
    private router:Router,public navCtrl:NavController) { }

  async ngOnInit() {
    let retVal=localStorage.getItem(StorageKeys.userDetail)
    let userInfo=retVal!=null?JSON.parse(retVal):'';//await this.storage.get(StorageKeys.userDetail);
    this.Companys=userInfo.Companys;
    this.isLoaded=true;
  }

  async onFormSubmit(ev:any){
    /*let selCompany = await this.storage.get(StorageKeys.selectedCompany);
    await this.storage.set(StorageKeys.selectedCompany,this.schoolNames);
    */
    let currentUrl = '/main/dashboard'
    await this.storage.set(StorageKeys.selectedCompany,ev);
    localStorage.setItem(StorageKeys.selectedCompany,ev);
    let item=this.Companys.findIndex((item:any)=>item.Company_ID===ev);
    await this.storage.set(StorageKeys.selectedCompanyName,this.Companys[item].Company_Name);
    localStorage.setItem(StorageKeys.selectedCompanyName,this.Companys[item].Company_Name)
    if(this.router.url===currentUrl){
      this.navCtrl.navigateRoot(currentUrl);
      window.location.reload();
    }
    else{
      this.router.navigate(['main/dashboard']);
    }

    this.dismiss();
  }

  dismiss(){
    this.modalCtrl.dismiss();
  }

}
