<ion-header>
  <ion-toolbar mode="ios" color="theme-green">
    <ion-title class="ion-text-center">Change School</ion-title>
    <ion-button slot="end" (click)="dismiss()" color="transparent">
      <ion-icon size="small" name="close" slot="icon-only"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>
<ion-content class="msg-card" *ngIf="isLoaded">
  <!-- <ion-card class="dashboardcard">
    <ion-grid>
      <ion-row>
          <ion-col size="3">
            <ion-label>School Name</ion-label>
          </ion-col>
          <ion-col size="9">
            <select [(ngModel)]="schoolNames" style="width:300px;" type="text" mode="ios" interface="popover">
              <option *ngFor="let item of Companys" [value]="item?.Company_ID">
                <ion-label>{{item?.Company_Name}}</ion-label>
              </option>
            </select>
          </ion-col>
        </ion-row>
    </ion-grid>
    <div class="btndiv">
      <ion-button class="btnformat" type="submit" (click)="onFormSubmit()"> Save </ion-button>
      <ion-button class="btnformat" type="reset" (click)="dismiss()"> Cancel </ion-button>
    </div>
    <br>
  </ion-card> -->
  <ion-label class="ion-padding">Select School to navigate</ion-label>
  <div *ngFor="let item of Companys">
    <ion-button class="btnformat" (click)="onFormSubmit(item.Company_ID)"  fill="transparent">
        {{item?.Company_Name}}
    </ion-button>
    <br>
  </div>
</ion-content>
