import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './authservice.service';
import { StorageService } from './storage.service';
import { StorageKeys } from '../models/storage.enum';
import { ListofValuesService } from './listofvalues.service';

@Injectable({
    providedIn: 'root',
})
export class DashboardService {

    public menuList$ = new BehaviorSubject([]);
    public pipeLine$ = new BehaviorSubject([]);
    public statistics$ = new BehaviorSubject([]);
    public conversionMetrics$ = new BehaviorSubject([]);
    public inqTypeCnt$ = new BehaviorSubject([]);
    public webleadList$ = new BehaviorSubject([]);
    public followupList$ = new BehaviorSubject([]);
    public toursList$ = new BehaviorSubject([]);
    public regList$ = new BehaviorSubject([]);
    public orientationList$ = new BehaviorSubject([]);
    public explorationList$ = new BehaviorSubject([]);
    public explorationallList$ = new BehaviorSubject([]);
    public oppPipeline$ = new BehaviorSubject([]);
    public oppWinPipeline$ = new BehaviorSubject([]);
    menuTitle: any = "";
    accessToken:string="";
    userDetail:any;
    pageClick = new BehaviorSubject("");
    public selectedCompany:any;
    public userInfo:any;

    constructor(private http: HttpClient, private authService: AuthService,
      public storage: StorageService,private lovService:ListofValuesService) { }

    async init() {
      await this.storage.get(StorageKeys.userDetail).then(res=>{
        this.userInfo=res;
        if(this.userInfo!=null){
          this.selectedCompany=this.userInfo.DefaultCompany_ID;
          this.getMenu();
        }
      })
    }

    getMenu(){
      return new Promise(async (resolve) => {
        this.accessToken = await this.storage.get(StorageKeys.AccessToken);
        let param;
        if (this.userInfo!=null) {
            param = {
              Company_ID: this.selectedCompany,
              USER_ID: this.userInfo.User_ID,
              AccessToken:this.accessToken
            }
            this.http.post<any>(environment.apiUrl + 'MenuItem', param).subscribe((resp) => {
                this.menuList$.next((resp && resp.length > 0) ? resp : []);
                resolve(resp);
            }, (err) => {
                console.log(err);
            })
        } else {
            resolve(null);
        }
      })
    }

    getPortalDetail(actionType: ("GetLead"|"GetWebLeads"|"GetFollowups"|"GetUpcomingOrientations"|"GetUpcomingAllTours"|"GetUpcomingTours"|"GetUpcomingExplorations"|"GetRecentRegistrations"), data?:any): Promise<any> {
      return new Promise(async (resolve) => {
          let userDetail = await this.authService.getUserDetail();
          this.accessToken = await this.storage.get(StorageKeys.AccessToken);
          let selectCompany = await this.storage.get(StorageKeys.selectedCompany);
          if(selectCompany!= "" && selectCompany != null){
            this.selectedCompany=parseInt(selectCompany);
          }
          let param:any = {
            Company_ID: this.selectedCompany,
            USER_ID: userDetail.User_ID,
            AccessToken:this.accessToken
           /*  RangeStart:this.selectedCompany,
            RangeEnd:10 */
          };
          if (userDetail!=null) {
              if (actionType == "GetWebLeads") {
                param["ActionType"]= "GetWebLeadList";
                param["CRMWebLoad"]=0;
              }
              else if(actionType == "GetFollowups"){
                param["ActionType"]= "GetFollowups";
                param["RangeStart"]=1;
                param["RangeEnd"]=10;
              }
              else if (actionType == "GetUpcomingOrientations") {
                param["ActionType"]= "GetRecentOrientations";
              }
              else if (actionType == "GetUpcomingTours") {
                param["ActionType"]= "GetRecentTours";
              }
              else if (actionType == "GetUpcomingAllTours") {
                param["ActionType"]= "GetRecentTours";
                param["Corporate"] = "Y"
              }
              else if (actionType == "GetUpcomingExplorations") {
                param["ActionType"]= "GetTopUpcomingExplorations";
              }
              /* param['ActionType'] = actionType;
              param['AccessToken'] = this.accessToken; */
              this.http.post<any>(environment.apiUrl + 'Lead', param).subscribe((resp) => {
                  if (actionType == "GetWebLeads") {
                      this.webleadList$.next((resp && resp.length > 0) ? resp : []);;
                  }
                  else if (actionType == "GetFollowups") {
                    this.followupList$.next((resp && resp.length > 0) ? resp : []);;
                  }
                  else if (actionType == "GetUpcomingOrientations") {
                    this.orientationList$.next((resp && resp.length > 0) ? resp : []);;
                  }
                  else if (actionType == "GetUpcomingTours" || actionType == "GetUpcomingAllTours"){
                    this.toursList$.next((resp && resp.length > 0) ? resp : []);;
                  }
                  resolve(resp);
              }, (err) => {
                  console.log(err);
              })
          } else {
              resolve(null);
          }
      })
  }

  getUpcomingExploration(actionType:any){
    return new Promise(async (resolve) => {
      let userDetail = await this.authService.getUserDetail();
      this.accessToken = await this.storage.get(StorageKeys.AccessToken);
      let selectCompany = await this.storage.get(StorageKeys.selectedCompany);
      if(selectCompany!= ""){
        this.selectedCompany=selectCompany;
      }
      let param:any = {
        Company_ID: this.selectedCompany,
        USER_ID: userDetail.User_ID,
        AccessToken:this.accessToken,
        Corporate:'N'
      };
      if (userDetail!=null) {
          if (actionType == "GetUpcomingExplorations") {
            param["ActionType"]= "GetUpcomingExplorations";
          }
          /* param['ActionType'] = actionType;
          param['AccessToken'] = this.accessToken; */
          let data=JSON.stringify(param,null,2);
          console.log(data);
          this.http.post<any>(environment.apiUrl + 'Dashboard', param).subscribe((resp) => {
                this.explorationList$.next((resp && resp.length > 0) ? resp : []);;
              resolve(resp);
          }, (err) => {
              console.log(err);
          })
      } else {
          resolve(null);
      }
    })
  }

  getAllExploration(actionType:any){
    return new Promise(async (resolve) => {
      let userDetail = await this.authService.getUserDetail();
      this.accessToken = await this.storage.get(StorageKeys.AccessToken);
      let selectCompany = await this.storage.get(StorageKeys.selectedCompany);
      if(selectCompany!= ""){
        this.selectedCompany=selectCompany;
      }
      let param:any = {
        Company_ID: this.selectedCompany,
        USER_ID: userDetail.User_ID,
        AccessToken:this.accessToken,
        Corporate:'Y'
      };
      if (userDetail!=null) {
          if (actionType == "GetUpcomingExplorations") {
            param["ActionType"]= "GetRecentExplorations";
          }
          /* param['ActionType'] = actionType;
          param['AccessToken'] = this.accessToken; */
          this.http.post<any>(environment.apiUrl + 'Lead', param).subscribe((resp) => {
                this.explorationallList$.next((resp && resp.length > 0) ? resp : []);;
              resolve(resp);
          }, (err) => {
              console.log(err);
          })
      } else {
          resolve(null);
      }
    })
  }

  getRegistrations(actionType:any){
    return new Promise(async (resolve) => {
      let userDetail = await this.authService.getUserDetail();
      this.accessToken = await this.storage.get(StorageKeys.AccessToken);
      let selectCompany = await this.storage.get(StorageKeys.selectedCompany);
      if(selectCompany!= ""){
        this.selectedCompany=selectCompany;
      }
      let param:any = {
        Company_ID: this.selectedCompany,
        USER_ID: userDetail.User_ID,
        AccessToken:this.accessToken
      };
      if (userDetail!=null) {
          if (actionType == "GetRegistrations") {
            param["ActionType"]= "GetRegistrations";
          }
          /* param['ActionType'] = actionType;
          param['AccessToken'] = this.accessToken; */
          this.http.post<any>(environment.apiUrl + 'Dashboard', param).subscribe((resp) => {
                this.regList$.next((resp && resp.length > 0) ? resp : []);;
              resolve(resp);
          }, (err) => {
              console.log(err);
          })
      } else {
          resolve(null);
      }
    })
  }

  async getAccessToken(){
    this.userDetail = await this.storage.get(StorageKeys.userDetail);
    this.accessToken = await this.storage.get(StorageKeys.AccessToken);
    let selectCompany = await this.storage.get(StorageKeys.selectedCompany);
    if(selectCompany!= ""){
      this.selectedCompany=selectCompany;
    }
  }

  getPipelineData(param:any){
    //return new Promise(async (resolve) => {
      //this.getAccessToken();
      //if (this.userDetail!=null) {
          /* let param = {
            Company_ID: this.selectedCompany,
            USER_ID: this.userDetail.User_ID,
            AccessToken:this.accessToken,
            ActionType: actionType,
            From_Date:fromDate,
            To_Date:toDate
          } */
          console.log(JSON.stringify(param,null,2));
          return this.http.post<any>(environment.apiUrl + 'Dashboard', param)
          /* .subscribe((resp) => {
            if (actionType=="GetInquiryTypeCount"){
              this.inqTypeCnt$.next((resp && resp.length > 0) ? resp : []);
            }
            else if (actionType=="GetStatistics"){
              this.statistics$.next((resp && resp.length > 0) ? resp : []);
            }
            else if (actionType=="GetLeadCountBasedonActuals" || actionType =="GetLeadCountBasedonLeads"){
              this.conversionMetrics$.next((resp && resp.length > 0) ? resp : []);
            }
            else if(actionType =="GetOpportunityWinBasedonActuals" || actionType =="GetOpportunityWinBasedonLeads" ){
              this.oppWinPipeline$.next((resp && resp.length > 0) ? resp : []);
            }
            else if(actionType =="GetOpportunityPipelineBasedonLeads" || actionType =="GetOpportunityPipelineBasedonActuals" ){
              this.oppPipeline$.next((resp && resp.length > 0) ? resp : []);
            }
            else{
              this.pipeLine$.next((resp && resp.length > 0) ? resp : []);
            }
            resolve(resp);
          }, (err) => {
              console.log(err);
          })
      }  else {
          resolve(null);
      }
    }) */
  }


}
