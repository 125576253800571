import { Injectable } from "@angular/core";
import { ToastController, LoadingController, ActionSheetController, AlertController, PopoverController, ModalController } from "@ionic/angular";
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject, Observable } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

@Injectable({
	providedIn: "root"
})

export class UiService {
	public spinnerMsg$ = new BehaviorSubject("");
	darkMode: any;

	constructor(private toastCtr: ToastController, private actionCtr: ActionSheetController, private loadingCtr: LoadingController, public spinner: NgxSpinnerService,
		private alertCtr: AlertController, private popOverCtr: PopoverController, private modelCtr: ModalController) { }

	// show sucess toaster messages
	showSuccess(msg:any) {

		this.getToastCtrl(msg, {
			color: "theme-pink"
		})
			.then(toastEl => {
				toastEl.present();
			})
			.catch(() => { });

	}

	// show error toaster messages
	showError(msg:any) {

		this.getToastCtrl(msg, {
			color: "danger"
		})
			.then(toastEl => {
				toastEl.present();
			})
			.catch(() => { });

	}
	showMessage(msg:any) {

		this.getToastCtrl(msg, {
			color: "dark"
		})
			.then(toastEl => {
				toastEl.present();
			})
			.catch(() => { });

	}
	// return the toaster
	private getToastCtrl(msg:any, options:any): Promise<HTMLIonToastElement> {

		return this.toastCtr.create({
			message: msg,
			duration: 2000,
			showCloseButton: true,
			position: "top",
			...options
		});

	}

	// return the loader
	getLoader() {

		return this.loadingCtr.create({
			spinner: null,
			message: "Please wait...",
			translucent: true,
			cssClass: "custom-class custom-loading"
		});

	}

	// return the action controller
	public getActionCtrl(header:any, options:any): Promise<HTMLIonActionSheetElement> {

		return this.actionCtr.create({
			header: header,
			...options
		});

	}

	// return the alert coontroller
	public getAlertCtrl(header:any, options:any): Promise<HTMLIonAlertElement> {

		return this.alertCtr.create({
			header: header,
			...options
		});

	}

	// return the popover controller
	public getPopOverCtr(options:any): Promise<HTMLIonPopoverElement> {

		return this.popOverCtr.create({ ...options })

	}

	// return the model controller
	public getModelCtr(options:any): Promise<HTMLIonModalElement> {

		return this.modelCtr.create({ ...options })

	}

	// used for display loader
	public pipeLoader(observable: Observable<any>) {

		let loader:any = null;
		let isDismissed = false;

		this.getLoader().then((tempLoader: any) => {
			loader = tempLoader;
			if (!isDismissed) loader.present();
		});

		return observable.pipe(
			mergeMap(data => {
				return new Promise((resolve, reject) => {
					isDismissed = true;
					if (loader) {
						loader.dismiss().then(() => {
							resolve(data);
						});
					} else {
						resolve(data);
					}
				});
			}),
			catchError(err => {
				return new Promise((resolve, reject) => {
					isDismissed = true;
					if (loader) {
						loader.dismiss().then(() => {
							reject(err);
						});
					} else {
						reject(err);
					}
				});
			})
		);
	}


	// user for handle error and return error messages
	public pipeErrorHandeler(observable: Observable<any>) {

		return observable.pipe(
			mergeMap(data => {
				return new Promise((resolve, reject) => {
					if (!data) {
						reject("unknown error");
					}
					else {
						resolve(data)
					}
				});
			}),
			catchError(err => {
				return new Promise((resolve, reject) => {
					reject(err);
				});
			})
		);

	}

	async spinnerShow(Msg: string) {
		await this.spinnerMsg$.next(Msg);
		await this.spinner.show();
		setTimeout(() => {
			this.spinner.hide();
		}, 10000);
	}
	async hideSpinner() {
		await this.spinner.hide();
	}

}
