import { Injectable } from '@angular/core';
import { UrlTree, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { StorageKeys } from 'src/app/models/storage.enum';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private navController: NavController, private storageService: StorageService) { }

  async canActivate(): Promise<UrlTree | boolean> {
    const userDetail = await this.storageService.get(StorageKeys.userDetail);
    const token = await this.storageService.get(StorageKeys.AccessToken);

    // If userDetail is exist then activate desired route
    if (userDetail) {
      return true;
    }

    // TODO: start with login page once are implemented
    return this.navController.navigateRoot('/login');
  }
}
