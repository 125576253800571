import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';
import { MenuPage } from './pages/menu/menu.page';
import { CommonModule } from '@angular/common';
import { Drivers } from '@ionic/storage';

@NgModule({
    declarations: [AppComponent, MenuPage],
    imports: [
      CommonModule,
      BrowserModule,
      IonicModule.forRoot(),
      AppRoutingModule,
      NgxSpinnerModule,
      HttpClientModule,
      IonicStorageModule.forRoot({
        name: '_vruttiCRM_app',
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
      }),
      BrowserAnimationsModule
    ],
    providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
