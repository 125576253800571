
  <ion-menu contentId="main-content" type="overlay" (ionWillOpen)="menuOpened()">
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-item lines="none">
          <ion-avatar>
            <img src="assets/images/Profile_pic.svg" />
          </ion-avatar>
          <ion-label *ngIf="userDetail">
            <h5><strong>{{fullName ? fullName : ''}}</strong></h5><br>
            <h5 style="color:black;">{{'Logged-in: '+ selectedCompany }}</h5>
          </ion-label>
        </ion-item>
      </ion-toolbar>
    </ion-header>
    <ion-content class="menu-content">
      <ion-list>
        <div *ngFor="let p of appPages; let i = index">
          <ion-menu-toggle *ngIf="p.SubMenu?.length==0">
            <ion-item lines="none" (click)="navigateTo(p.title)" class="ion-no-padding menu-title"
              [ngClass]="p.isSelected?'selected':''">
                  <ion-icon class="title-icon" src="/assets/images/menu/DASHBOARDS.png"></ion-icon>
            </ion-item>
          </ion-menu-toggle>
          <ion-item *ngIf="p.SubMenu && p.SubMenu.length>0 && p.isVisible" class="ion-no-padding " lines="none" detail="false"
            (click)="selectedIndex == i ? selectedIndex=null : selectedIndex = i">
            <img class="title-icon" [src]="getIconImage(p.title)" />
            <ion-label class="menu-title">{{ p.title}}</ion-label>
            <ion-button color="transparent" mode="ios" *ngIf="p.SubMenu && p.isVisible && p.SubMenu.length>0 && selectedIndex != i">
              <ion-icon class="title-icon" name="chevron-forward" slot="end"></ion-icon>
            </ion-button>
            <ion-button color="transparent" mode="ios" *ngIf="p.SubMenu && p.isVisible && p.SubMenu.length>0 && selectedIndex == i">
              <ion-icon class="title-icon" name="chevron-down" slot="end"></ion-icon>
            </ion-button>
          </ion-item>
          <div *ngIf="p.SubMenu && p.SubMenu.length>0 && selectedIndex == i && p.isVisible">
            <ion-menu-toggle auto-hide="false">
              <ng-container *ngFor="let subitem of p.SubMenu; let i = index">
                <ion-item line="none" class="ion-no-padding submenu" (mousedown)=" navigateTo(subitem.title)" (click)=" navigateTo(subitem.title)" lines="none"
                  detail="false" *ngIf="subitem.title !=='Dark Mode'">
                  <img class="title-icon" [src]="getIconImage(subitem.title,true)" />
                  <ion-label class="menu-title">{{ subitem.title }}</ion-label>
                </ion-item>
                <ion-item *ngIf="subitem.title=='Dark Mode'" line="none" class="ion-no-padding submenu"
                  (click)="modeChange()" lines="none" detail="false">
                  <img class="title-icon" [src]="getIconImage('Theme')" />
                  <ion-label class="menu-title">Theme</ion-label>
                </ion-item>
              </ng-container>
            </ion-menu-toggle>
          </div>
        </div>
      </ion-list>
      <!-- <ion-item hidden>
        <ion-label>Select Theme
        </ion-label>
        <ion-select mode="md" (ionChange)="selectTheme($event)" placeholder="Select Theme" #mySelect>
          <ion-select-option *ngFor="let theme of themeOptions" [value]="theme.value">{{theme.label}}</ion-select-option>
        </ion-select>
      </ion-item> -->
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="main-content"></ion-router-outlet>
  <!-- </ion-split-pane> -->
